import { MetaDataInterface } from '@components/MetaData';
import { getPageFromQueryObject } from '@marine/utils';
import { GetServerSideProps } from 'next';
import { useMemo } from 'react';
import { BlogLayout } from 'sailor-ui/Layouts/BlogLayout';
import { BlogJumbotron } from 'sailor-ui/Layouts/BlogLayout/BlogLayout.props';
import { BlogsPage } from 'sailor-ui/pages/next';
import { GetPostsCardItem, GetPostsCardMeta } from 'services';
import { GetPostsCardVariables, useGetPostsCard } from 'services/graphql/cms';
import { Layout } from '../components/Layout';
import { cmsGraphQLClient } from '../graphQLClient';
import { getAppConfiguration } from '../utils/browserEnv';

const { appCode } = getAppConfiguration();

type Props = {
  posts: GetPostsCardItem[];
  meta: GetPostsCardMeta;
};

const jumbotron: BlogJumbotron = {
  title: 'Blog',
  description:
    'Your source for the latest news on yachts, boats and more. Read through our articles to find out how to compare boats and find the right fit for you!',
};

const Home = ({ posts, meta }: Props) => {
  const metaData: MetaDataInterface = {
    title: 'Blog',
    description: `Your source for the latest news on yachts, 
      boats and more. Read through our articles to find out how to compare boats and find the right fit for you!`,
    keyword: ['boat management and maintenance app', 'yacht management and maintenance app'],
  };

  const featuredPost = useMemo<GetPostsCardItem | undefined>(() => {
    if (meta?.pagination?.page === 1) {
      if (posts[0]) {
        return posts[0];
      }
    }
  }, [meta?.pagination?.page, posts]);

  const allPosts = useMemo<GetPostsCardItem[]>(() => {
    if (meta?.pagination?.page === 1) {
      if (posts.length > 1) {
        return posts.slice(1, -1);
      }
    }

    return posts || [];
  }, [meta?.pagination?.page, posts]);

  return (
    <Layout meta={metaData}>
      <BlogLayout appCode={appCode} featuredPost={featuredPost} jumbotron={jumbotron}>
        <BlogsPage posts={allPosts} meta={meta} appCode={appCode} />
      </BlogLayout>
    </Layout>
  );
};

export const getServerSideProps: GetServerSideProps<Props> = async ({ query }) => {
  const pageSize = getPageFromQueryObject(query, 'pageSize', 10);
  const page = getPageFromQueryObject(query, 'page', 1);

  const variables: GetPostsCardVariables = {
    appCode,
    pageSize,
    page,
  };

  const postsData = await useGetPostsCard.fetcher(cmsGraphQLClient, variables)();

  if (postsData?.posts?.data?.length) {
    const { data, meta } = postsData.posts;

    return {
      props: {
        posts: data,
        meta,
      },
    };
  }

  return {
    notFound: true,
  };
};

export default Home;
